import CheckboxSelectAll from "stimulus-checkbox-select-all"
import StimulusReflex from "stimulus_reflex";
export default class extends CheckboxSelectAll {
  connect () {
    super.connect()
    console.log('bulk_timesheet conected')
    StimulusReflex.register(this);
  }
  afterReflex (element, reflex, noop, reflexId) {
    // super.afterReflex (element, reflex, noop, reflexId)
    // console.log('afterReflex bulk')
    countCheckboxes();
    // initForReflex();
  }
}
