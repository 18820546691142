import ApplicationController from './application_controller'
import StimulusReflex from 'stimulus_reflex'
export default class extends ApplicationController {
  static targets = ['hourId', 'hourRejection']

  connect () {
    super.connect();
    StimulusReflex.register(this);
  }

  keydown (event) {
    if(event.keyCode == 13){
      event.preventDefault();
      var hourId = this.hourIdTarget.value;
      var hourRejection = this.hourRejectionTarget.value;
      this.stimulate('HourReflex#reject_hour_js', hourId, hourRejection);
    }
  }

  afterReflex (element, reflex, noop, reflexId) {
    console.log('HoursController#afterReflex initForReflex()' );
    initForReflex();
  }
}