import ApplicationController from './application_controller'
export default class extends ApplicationController {
  connect () {
    super.connect()
    // console.log('Agenda conroller: connect');
    initSelect2();
  }

  afterReflex (element, reflex, noop, reflexId) {
    console.log('Agenda conroller: afterReflex');
    initForReflex();
    initSelect2();
    element.focus()
  }
}
