import ContentLoader from "stimulus-content-loader"

export default class extends ContentLoader {
  connect() {
    super.connect()
  }

  loadScripts() {
    super.loadScripts()
    initForReflex();
  }
}