import ApplicationController from './application_controller'
import consumer from 'channels/consumer'
export default class extends ApplicationController {
  static targets = ['messages', 'conversation_container']

  connect() {
    this.subscription = consumer.subscriptions.create({ channel: 'ChatMessageChannel', id: this.data.get('id')}, {
      connected: this._connected.bind(this),
      disconnected: this._disconnected.bind(this),
      received: this._received.bind(this),
    })
  }

  disconnect() {
    consumer.subscriptions.remove(this.subscription);
  }

  _connected() {

  }
  _disconnected() {

  }
  _received(data) {
    if (data.message) {
      this.messagesTarget.insertAdjacentHTML('beforeend', data.message)
      this.scrollToBottom()
      if (!document.hidden) {
        this.subscription.perform('touch')
      }
    } else if(data.destroyed) {
      $('#'+data.destroyed.id).replaceWith(data.destroyed.message)
    }
  }
  scrollToBottom() {
    this.conversation_containerTarget.scrollTop = this.conversation_containerTarget.scrollHeight;
  }
}