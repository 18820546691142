import CheckboxSelectAll from "stimulus-checkbox-select-all"
import StimulusReflex from "stimulus_reflex";
export default class extends CheckboxSelectAll {
  connect () {
    super.connect()
    StimulusReflex.register(this);
  }
  afterReflex (element, reflex, noop, reflexId) {
    initForReflex();
  }
}