import ApplicationController from './application_controller'
export default class extends ApplicationController {
  connect () {
    super.connect()
    console.log('date check conroller: connect');
  }
  afterReflex (element, reflex, noop, reflexId) {
    console.log('DateCheck#afterReflex initForReflex()' );
    initForReflex();
  }
  accept () {
    console.log('date check conroller: accept');
  }
}
