import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect () {
    super.connect()
  }

  afterReflex(element, reflex, noop, reflexId) {
    console.log('my_availability#afterReflex');
    initForReflex();
  }
}