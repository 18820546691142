// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import StimulusReflex from 'stimulus_reflex'
import consumer from '../channels/consumer'
import controller from './application_controller'
import debounced from 'debounced'

import Appsignal from "@appsignal/javascript";
import { installErrorHandler } from "@appsignal/stimulus";

const application = Application.start()

const appsignal = new Appsignal({
  key: process.env.APPSIGNAL_JAVASCRIPT_API_KEY,
});
console.log('Starting AppSignal');
installErrorHandler(appsignal, application);

const context = require.context("controllers", true, /_controller\.(js|ts)$/)
application.load(definitionsFromContext(context))
// application.consumer = consumer
StimulusReflex.initialize(application, { consumer, controller, isolate: true })
StimulusReflex.debug = process.env.RAILS_ENV === 'development'

var debounced_wait_time = (process.env.RAILS_ENV === "test") ? 25 : 300
debounced.initialize({ input: { wait: debounced_wait_time } })
