import ApplicationController from './application_controller'
import Sortable from "sortablejs"
import { put, get, post, patch, destroy } from "@rails/request.js"

export default class extends ApplicationController {
  connect () {
    super.connect()
    console.log(this.element)
    const columnLeft = document.querySelector(".column-left");
    this.sortable = Sortable.create(columnLeft, {
      group: 'shared',
      animation: 150,
      onEnd: this.updatePosition.bind(this),
      onAdd: this.updatePosition.bind(this)

    })

    const columnRight = document.querySelector(".column-right");
    this.sortable = Sortable.create(columnRight, {
        group: {
          name: 'shared',
          pull: 'clone',
          put: false // Do not allow items to be put into this list
        },
        animation: 150,
        sort: false
    })

  }

  async updatePosition(event) {
    const response = await put(this.data.get('url'), {
      body: JSON.stringify({
        sgid: event.item.dataset.sgid,
        landing_block_type: event.item.dataset.landingblocktype,
        position: event.newIndex
      })
    })
     if (response.ok) {
       console.log(event.item.dataset.sgid)
     }
     window.location.reload();
  }
}


