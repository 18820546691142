import ApplicationController from "./application_controller"
import StimulusReflex from "stimulus_reflex"

export default class extends ApplicationController {
  static targets = ["vat", "agb", "kvk", "company"]

  connect () {
    super.connect()
    StimulusReflex.register(this);
    console.log("Company conroller: connect");
  }

  afterReflex (element, reflex, noop, reflexId) {
    console.log("Company conroller: afterReflex");
    initForReflex(false);
  }

  vat_check(event) {
    event.preventDefault();
    var vat_value = this.vatTarget.value;
    this.stimulate("Company#vat_check", vat_value);
  }

  agb_check(event) {
    console.log("Company conroller: click");
    event.preventDefault();
    var agb_value = this.agbTarget.value;
    this.stimulate("Company#agb_check", agb_value);
  }

  kvk_search(e) {
    e.preventDefault();
    var kvk_value = this.kvkTarget.value;
    var company_value = this.companyTarget.value;
    this.stimulate("Company#kvk_search", kvk_value, company_value);
  }

}
