import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect () {
    console.log('RequisitionInvite#connect()' );
    super.connect()
  }

  afterReflex (element, reflex, noop, reflexId) {
    console.log('RequisitionInvite#afterReflex' );
    initForReflex();
  }

  preventSubmit(event) {
    console.log("preventing the form submission")
    if (event.key == 'Enter') {
      event.preventDefault();
    }
  }
}
