import ApplicationController from './application_controller'
import consumer from 'channels/consumer'
export default class extends ApplicationController {
  static targets = ["new_count", 'last_message']

  connect() {
    this.subscription = consumer.subscriptions.create({ channel: 'UnreadsChannel', id: this.data.get('id')}, {
      connected: this._connected.bind(this),
      disconnected: this._disconnected.bind(this),
      received: this._received.bind(this),
    })
  }

  disconnect() {
    consumer.subscriptions.remove(this.subscription);
  }

  _connected() {

  }
  _disconnected() {

  }
  _received(data) {
    if (data.message) {
      if(data.creator_id != this.data.get('user_id')) {
        if(this.data.get('current_conversation_id') != this.data.get('id')) {
          this.element.classList.add('unread')
          let count = parseInt(this.new_countTarget.textContent)
          this.new_countTarget.textContent = count + 1
          this.new_countTarget.removeAttribute("hidden")
        }
        this.last_messageTarget.textContent = data.message

        this.notify('Nieuwe chat: ' + data.message)
      }
    }
  }
}