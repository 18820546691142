import ApplicationController from './application_controller'
import Sortable from "sortablejs"
import { put, get, post, patch, destroy } from "@rails/request.js"
export default class extends ApplicationController {
  connect () {
    super.connect()
    this.sortable = Sortable.create(this.element, {
      handle: '.sort-handle',
      animation: 150,
      onEnd: this.updatePosition.bind(this)
    })
  }

  async updatePosition(event) {
    const response = await put(this.data.get('url'), {
      body: JSON.stringify({
        sgid: event.item.dataset.sgid,
        field_order: event.newIndex
      })
    })
    // if (response.ok) {
    //   console.log(event.item.dataset.sgid)
    // }
  }

}