import ApplicationController from './application_controller'
import consumer from 'channels/consumer'
export default class extends ApplicationController {
  static targets = ["conversationlist"]

  connect() {
    this.subscription = consumer.subscriptions.create({ channel: 'NewconversationChannel', id: this.data.get('id')}, {
      connected: this._connected.bind(this),
      disconnected: this._disconnected.bind(this),
      received: this._received.bind(this),
    })
  }

  disconnect() {
    consumer.subscriptions.remove(this.subscription);
  }

  _connected() {}

  _disconnected() {}

  _received(data) {
    // console.log('data received');
    if (data.message) {
      this.conversationlistTarget.insertAdjacentHTML('afterbegin', data.message)
    }

    this.notify(data.body)
  }
}
