import CheckboxSelectAll from "stimulus-checkbox-select-all"
import StimulusReflex from 'stimulus_reflex'

export default class extends CheckboxSelectAll {
  connect () {
    console.log("reflex connected.");
    StimulusReflex.register(this);
    super.connect()
  }
}
